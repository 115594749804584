import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrAdminNewBlackListForm'
})
export default class GtrAdminNewBlackListForm extends Vue {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    @Ref()
    readonly observerForm!: InstanceType<typeof ValidationObserver>

    data () {
      return {
        submitting: false,
        value: false,
        blackList: {
          name: null
        },
        table: {
          headers: [
            { text: 'Email', align: 'start', sortable: false, value: 'email' },
            { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
          ],
          items: []
        },
        email: null
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleChange (payload: any) {
      this.$data.value = payload
    }

    onClose () {
      this.$data.value = false
      this.$emit('close')
    }

    addEmailToBlackList () {
      if (this.$data.email) {
        this.$data.table.items.push({
          email: this.$data.email
        })
        this.$data.email = null
      }
    }

    deleteEmailFromBlackList (email: any) {
      this.$data.table.items.splice(this.$data.table.items.indexOf(email), 1)
    }

    async submit () {
      const form = (this.$refs.newBlackListForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const emails: any [] = []
          this.$data.table.items.map(item => {
            emails.push(item.email)
          })
          const payload: any = {
            company_uuid: this.$route.params.uuid,
            data: {
              name: this.$data.blackList.name,
              emails
            }
          }
          await this.$store.dispatch('company/saveBlacklist', payload)
          Container.get(Notification).success('BlackList successfully created.')
          this.$bus.$emit('gtr.level-one.new-blacklist')
          this.$data.blackList.name = null
          this.$data.table.items = []
          this.observerForm.reset()
          this.onClose()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }
}
