import { Component, Vue, Watch } from 'vue-property-decorator'
import GtrAdminLayout from '@/modules/common/views/layouts/level-one/admin/admin.layout.vue'
import { mapState } from 'vuex'
import GtrAdminNewBlackListForm from './forms/new/new.form.vue'
import GtrAdminEditBlackListForm from './forms/edit/edit.form.vue'

@Component({
  name: 'GtrAdminCompanyBlackListView',
  computed: {
    ...mapState('company', ['companyBlacklists'])
  },
  components: {
    'gtr-new-blacklist-form': GtrAdminNewBlackListForm,
    'gtr-edit-blacklist-form': GtrAdminEditBlackListForm
  }
})
export default class GtrAdminCompanyBlackListView extends Vue {
  data () {
    return {
      loading: false,
      title: '',
      table: {
        headers: [
          { text: 'Name', align: 'start', sortable: false, value: 'name' },
          { text: 'Created At', value: 'created_at', searchable: false, sortable: false, width: '200px' },
          { text: 'Updated At', value: 'updated_at', searchable: false, sortable: false, width: '200px' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      forms: {
        showFormNewBlackList: false,
        showFormEditBlackList: false
      },
      blackListToEdit: null
    }
  }

  @Watch('companyBlacklists')
  onCompanyBlackListChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
    }
  }

  created () {
    this.$emit('update:layout', GtrAdminLayout)
    this.$bus.$on('gtr.level-one.new-blacklist', this.fetchCompanyBlackLists)
    this.$bus.$on('gtr.level-one.edit-blacklist', this.fetchCompanyBlackLists)
  }

  async mounted () {
    let company: any = null
    const companies: any [] = this.$store.state.company.companies
    if (companies.length > 0) {
      company = companies.find((company: any) => company.uuid === this.$route.params.uuid)
      if (company) {
        this.$data.title = `${company.name} BlackLists`
      }
    } else {
      company = await this.$store.dispatch('company/fetchCompany', this.$route.params.uuid)
      if (company) {
        this.$data.title = `${company.name} BlackLists`
      }
    }
    await this.fetchCompanyBlackLists()
  }

  handleShowNewBlackListForm () {
    this.$data.forms.showFormNewBlackList = true
  }

  handleCloseNewBlackListForm () {
    this.$data.forms.showFormNewBlackList = false
  }

  handleShowEditBlackListForm (payload: any) {
    this.$data.blackListToEdit = payload
    this.$data.forms.showFormEditBlackList = true
  }

  handleCloseEditBlackListForm () {
    this.$data.blackListToEdit = null
    this.$data.forms.showFormEditBlackList = false
  }

  private async fetchCompanyBlackLists () {
    this.$data.loading = true
    await this.$store.dispatch('company/getCompanyBlacklists', { company_uuid: this.$route.params.uuid })
    this.$data.loading = false
  }
}
