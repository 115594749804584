import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrAdminEditBlackListForm'
})
export default class GtrAdminEditBlackListForm extends Vue {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    @Prop({ required: true, type: Object, default: {} })
    blackList: object|undefined

    @Ref()
    readonly observerForm!: InstanceType<typeof ValidationObserver>

    data () {
      return {
        submitting: false,
        value: false,
        model: {},
        table: {
          headers: [
            { text: 'Email', align: 'start', sortable: false, value: 'email' },
            { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
          ],
          items: []
        },
        email: null
      }
    }

    @Watch('blackList', { immediate: true })
    onBlackListChange (payload: any) {
      if (payload) {
        this.$data.model = payload
        payload.emails.map((email: string) => {
          this.$data.table.items.push({
            email: email
          })
        })
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleChange (payload: any) {
      this.$data.value = payload
    }

    onClose () {
      this.$data.value = false
      this.$emit('close')
    }

    addEmailToBlackList () {
      if (this.$data.email) {
        this.$data.table.items.push({
          email: this.$data.email
        })
        this.$data.email = null
      }
    }

    deleteEmailFromBlackList (email: any) {
      this.$data.table.items.splice(this.$data.table.items.indexOf(email), 1)
    }

    async submit () {
      const form = (this.$refs.editBlackListForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const emails: any [] = []
          this.$data.table.items.map(item => {
            emails.push(item.email)
          })
          const payload: any = {
            company_uuid: this.$route.params.uuid,
            blacklist_uuid: this.$data.model.uuid,
            data: {
              name: this.$data.model.name,
              emails
            }
          }
          await this.$store.dispatch('company/updateBlacklist', payload)
          Container.get(Notification).success('BlackList successfully edited.')
          this.$bus.$emit('gtr.level-one.edit-blacklist')
          this.$data.model.name = null
          this.$data.table.items = []
          this.observerForm.reset()
          this.onClose()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }
}
